.text-view {
  input {
    background-color: transparent !important;
    border-color: rgb(117 117 117 / var(--tw-border-opacity)) !important;
    border-top: none;
    border-width: 1px !important;
    cursor: text;
  }

  label {
    color: #d8d8e8 !important;

    &::before,
    &::after {
      border-color: rgb(117 117 117 / var(--tw-border-opacity)) !important;
    }
  }
}
