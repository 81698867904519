.select-field {
  &__search {
    display: none;
  }

  &[aria-expanded="true"] {
    .select-field__search {
      display: block;
    }

    .select-field__selected {
      display: none;
    }
  }

  &[aria-expanded="false"] {
    .select-field__selected {
      display: block;
    }
  }
}
