@tailwind base;
@tailwind components;
@tailwind utilities;

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #d8d8e8;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9);
}

[role="alert"] {
  div.mr-12 {
    margin-right: 0;
  }
}

.Toastify {
  z-index: 999999;
}

.language-picker {
  [aria-expanded="true"] {
    border-top-color: rgb(33 150 243 / var(--tw-border-opacity));
  }

  [aria-expanded="false"] {
    border-top-color: rgb(117 117 117 / var(--tw-border-opacity));
  }
 }
